<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="energy" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagPeriods"
            @show="focusDiag"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Begasungs-Perioden Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periods')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true" :activeIndex="diagPeriod">
                    <TabPanel v-for="(element, index) in this.nodeDataPeriods" :key="index">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataPeriods(index, 'periodEnable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataPeriods(index, 'periodName').value }}</span>
                            </div>
                        </template>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Perioden-Hauptschalter`">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'periodEnable').value" /><span class="ml-2">Periode aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>   
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - CO2 ppm Sollwert`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Der CO2-Sollwert gibt den gewünschten ppm-Sollwert in der Periode an.`"></i>
                                <i class="fi fi-rr-bottle bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="co2Setpoint" ref="co2Setpoint" :min="0" :max="2000"
                                                v-model="this.getDiagDataPeriods(index, 'co2Setpoint').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="co2Setpoint" class="mr-2">{{ this.getDiagDataPeriods(index, 'co2Setpoint').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'co2Setpoint').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'co2Enable').value" inputId="co2Enable" :binary="true" />
                                            <label for="co2Enable" class="ml-2"> {{ this.getDiagDataPeriods(index, 'co2Enable').label }} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel> 
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Zeiteinstellungen`" class="mt-2" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataPeriods(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Sollwertkorrektur Strahlung`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Lichtführung korrigiert den Sollwert der Periode um den unter Korrektur eingetragenen ppm-Wert. Innerhalb des Start- und Endpunktes der Lichtführung wird der Sollwert linear korrigiert.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'lightCorrectionEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'lightCorrectionEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'lightCorrectionEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightCorrectionStart" ref="lightCorrectionStart" :min="0" :max="3000"
                                                v-model="this.getDiagDataPeriods(index, 'lightCorrectionStart').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="lightCorrectionStart" class="mr-2">{{ this.getDiagDataPeriods(index, 'lightCorrectionStart').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'lightCorrectionStart').unit }}</span>
                                        </span>
                                    </div>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataPeriods(index, 'lightCorrectionStart').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightCorrectionEnd" ref="lightCorrectionEnd" :min="0" :max="3000"
                                                v-model="this.getDiagDataPeriods(index, 'lightCorrectionEnd').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="lightCorrectionEnd" class="mr-2">{{ this.getDiagDataPeriods(index, 'lightCorrectionEnd').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'lightCorrectionEnd').unit }}</span>
                                        </span>
                                    </div>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataPeriods(index, 'lightCorrectionEnd').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightCorrectionValue" ref="lightCorrectionValue" :min="-2000" :max="2000"
                                                v-model="this.getDiagDataPeriods(index, 'lightCorrectionValue').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="lightCorrectionValue" class="mr-2">{{ this.getDiagDataPeriods(index, 'lightCorrectionValue').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'lightCorrectionValue').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Lüftungs Funktion`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Lüftungsfunktion kann die Begasung sperren, wenn die unten angegebenen Paramenter nicht zutreffend sind. Wenn die Freigabefunktion (Schalter) deaktiviert ist, wird diese Funktion nicht verwendet und die Freigabe ist erfüllt.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'disableWithVentsEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithVentsEnable').value">
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithVentsLuvMin" ref="disableWithVentsLuvMin" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithVentsLuvMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithVentsLuvMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsLuvMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithVentsLuvMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithVentsLuvMax" ref="disableWithVentsLuvMax" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithVentsLuvMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithVentsLuvMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsLuvMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithVentsLuvMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithVentsLeeMin" ref="disableWithVentsLeeMin" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithVentsLeeMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithVentsLeeMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsLeeMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithVentsLeeMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-3">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithVentsLeeMax" ref="disableWithVentsLeeMax" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithVentsLeeMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithVentsLeeMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsLeeMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithVentsLeeMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithVentsEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="co2SetpointMax" ref="co2SetpointMax" :min="0" :max="2000"
                                                v-model="this.getDiagDataPeriods(index, 'co2SetpointMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="co2SetpointMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'co2SetpointMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'co2SetpointMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="co2SetpointMaxVents" ref="co2SetpointMaxVents" :min="-100" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'co2SetpointMaxVents').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="co2SetpointMaxVents" class="mr-2">{{ this.getDiagDataPeriods(index, 'co2SetpointMaxVents').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'co2SetpointMaxVents').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="isMaster">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithVentsHyst" ref="disableWithVentsHyst" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithVentsHyst').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithVentsHyst" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithVentsHyst').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithVentsHyst').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Raumtemperatur Funktion`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Raumtemperatur Funktion kann die Begasung sperren, wenn die unten angegebenen Paramenter nicht zutreffend sind. Wenn die Freigabefunktion (Schalter) deaktiviert ist, wird diese Funktion nicht verwendet und die Freigabe ist erfüllt.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'disableWithRtEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'disableWithRtEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithRtEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRtMin" ref="disableWithRtMin" :min="-50" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRtMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRtMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRtMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRtMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRtMax" ref="disableWithRtMax" :min="-50" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRtMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRtMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRtMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithRtMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="isMaster">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRtHyst" ref="disableWithRtHyst" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRtHyst').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRtHyst" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRtHyst').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRtHyst').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Raumfeuchte Funktion`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Raumfeuchte Funktion kann die Begasung sperren, wenn die unten angegebenen Paramenter nicht zutreffend sind. Wenn die Freigabefunktion (Schalter) deaktiviert ist, wird diese Funktion nicht verwendet und die Freigabe ist erfüllt.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'disableWithRhEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'disableWithRhEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithRhEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhMin" ref="disableWithRhMin" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRhMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhMax" ref="disableWithRhMax" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithRhMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="isMaster">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhHyst" ref="disableWithRhHyst" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhHyst').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhHyst" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhHyst').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRhHyst').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Raumfeuchte-Defizit Funktion`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Raumfeuchte-Defizit Funktion kann die Begasung sperren, wenn die unten angegebenen Paramenter nicht zutreffend sind. Wenn die Freigabefunktion (Schalter) deaktiviert ist, wird diese Funktion nicht verwendet und die Freigabe ist erfüllt.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'disableWithRhDefEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'disableWithRhDefEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithRhDefEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhDefMin" ref="disableWithRhDefMin" :min="0" :max="50"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhDefMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhDefMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhDefMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRhDefMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhDefMax" ref="disableWithRhDefMax" :min="0" :max="50"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhDefMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhDefMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhDefMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithRhDefMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="isMaster">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithRhDefHyst" ref="disableWithRhDefHyst" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithRhDefHyst').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithRhDefHyst" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithRhDefHyst').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithRhDefHyst').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Wind Funktion`" class="mt-3" v-if="this.getDiagDataPeriods(index, 'periodEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Wind Funktion kann die Begasung sperren, wenn die unten angegebenen Paramenter nicht zutreffend sind. Wenn die Freigabefunktion (Schalter) deaktiviert ist, wird diese Funktion nicht verwendet und die Freigabe ist erfüllt.`"></i>
                                <i class="fi fi-rr-chat-arrow-down bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'disableWithWindEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'disableWithWindEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataPeriods(index, 'disableWithWindEnable').value">
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithWindMin" ref="disableWithWindMin" :min="0" :max="50"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithWindMin').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithWindMin" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithWindMin').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithWindMin').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithWindMax" ref="disableWithWindMax" :min="0" :max="50"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithWindMax').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithWindMax" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithWindMax').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text">{{ this.getDiagDataPeriods(index, 'disableWithWindMax').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-4" v-if="isMaster">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="disableWithWindHyst" ref="disableWithWindHyst" :min="0" :max="100"
                                                v-model="this.getDiagDataPeriods(index, 'disableWithWindHyst').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-xl"  />
                                                <label for="disableWithWindHyst" class="mr-2">{{ this.getDiagDataPeriods(index, 'disableWithWindHyst').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-md">{{ this.getDiagDataPeriods(index, 'disableWithWindHyst').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Periodenbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataPeriods(index, 'periodName'))" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagTogglePeriods" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('periods')" :disabled="periodCheck" />
                    </div>
                </div>
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagMinMax"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Regler Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('minmax')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Freigabe">
                        <Panel header="Hauptschalter Begasungsprogramm" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                    Der Hauptschalter aktiviert oder deaktiviert das Begasungsprogramm übergeordnet der Perioden.
                                </InlineMessage>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataMinMax('controlEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('controlEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarme CO2">
                        <Panel header="Alarmeinstellungen CO2-Minimum ppm">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmCo2Min" ref="alarmCo2Min" :min="0" :max="10000"
                                            v-model="getDiagDataMinMax('alarmCo2Min').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmCo2Min').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmCo2Min" class="mr-2">{{ getDiagDataMinMax('alarmCo2Min').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmCo2MinDelay" ref="alarmCo2MinDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('alarmCo2MinDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmCo2MinDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmCo2MinDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmCo2MinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen CO2-Maximum ppm" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmCo2Max" ref="alarmCo2Max" :min="0" :max="10000"
                                            v-model="getDiagDataMinMax('alarmCo2Max').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmCo2Max').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmCo2Max" class="mr-2">{{ getDiagDataMinMax('alarmCo2Max').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmCo2MaxDelay" ref="alarmCo2MaxDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('alarmCo2MaxDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmCo2MaxDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmCo2MaxDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmCo2MaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen CO2-Differenz ppm" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmCo2Diff" ref="alarmCo2Diff" :min="-50" :max="100"
                                            v-model="getDiagDataMinMax('alarmCo2Diff').value" mode="decimal"
                                            :suffix="` ${getDiagDataMinMax('alarmCo2Diff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmCo2Diff" class="mr-2">{{ getDiagDataMinMax('alarmCo2Diff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmCo2DiffDelay" ref="alarmCo2DiffDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('alarmCo2DiffDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmCo2DiffDelay" class="mr-2">{{ this.getDiagDataMinMax('alarmCo2DiffDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataMinMax('alarmCo2DiffPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Reglerparameter" v-if="isMaster">
                        <Panel header="Regelungsart" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataMinMax('useLiquidCo2').value" inputId="useLiquidCo2" :binary="true" />
                                            <label for="useLiquidCo2" class="ml-2"> {{ this.getDiagDataMinMax('useLiquidCo2').label }} </label>
                                        </div>
                                        <div class="flex align-items-center ml-3">
                                            <Checkbox v-model="this.getDiagDataMinMax('useBurnerCo2').value" inputId="useBurnerCo2" :binary="true" />
                                            <label for="useBurnerCo2" class="ml-2"> {{ this.getDiagDataMinMax('useBurnerCo2').label }} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Pulsierendes Ventil" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-4">
                                            <InputSwitch v-model="this.getDiagDataMinMax('co2UsePulse').value" /><span class="ml-2">{{ this.getDiagDataMinMax('co2UsePulse').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid" v-if="this.getDiagDataMinMax('co2UsePulse').value">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="co2UsePulseOn" ref="co2UsePulseOn" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('co2UsePulseOn').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="co2UsePulseOn" class="mr-2">{{ this.getDiagDataMinMax('co2UsePulseOn').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="co2UsePulseOff" ref="co2UsePulseOff" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('co2UsePulseOff').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="co2UsePulseOff" class="mr-2">{{ this.getDiagDataMinMax('co2UsePulseOff').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Reglerparameter" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="controlHyst" ref="controlHyst" :min="0" :max="2000"
                                            v-model="this.getDiagDataMinMax('controlHyst').value"
                                            :suffix="' ' + this.getDiagDataMinMax('controlHyst').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="controlHyst" class="mr-2">{{ this.getDiagDataMinMax('controlHyst').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Zeiten" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="controlOnDelay" ref="controlOnDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('controlOnDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="controlOnDelay" class="mr-2">{{ this.getDiagDataMinMax('controlOnDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="controlOffDelay" ref="controlOffDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('controlOffDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="controlOffDelay" class="mr-2">{{ this.getDiagDataMinMax('controlOffDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="forceoffTime" ref="forceoffTime" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('forceoffTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="forceoffTime" class="mr-2">{{ this.getDiagDataMinMax('forceoffTime').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sensoreinstellungen" v-if="isMaster">
                        <Panel header="Sensorskalierung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensInMin" ref="sensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataMinMax('sensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensOutMin" ref="sensOutMin" :min="-10000" :max="10000"
                                                v-model="this.getDiagDataMinMax('sensOutMin').value"
                                                :suffix="` ${this.getDiagDataMinMax('sensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensInMax" ref="sensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataMinMax('sensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensOutMax" ref="sensOutMax" :min="-10000" :max="10000"
                                                v-model="this.getDiagDataMinMax('sensOutMax').value"
                                                :suffix="` ${this.getDiagDataMinMax('sensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensOffset" ref="sensOffset" :min="-10000" :max="10000"
                                                v-model="this.getDiagDataMinMax('sensOffset').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensOffset" class="mr-2">{{ this.getDiagDataMinMax('sensOffset').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Filtereinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensFilterValue" ref="sensFilterValue" :min="0" :max="100000"
                                                v-model="this.getDiagDataMinMax('sensFilterValue').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataMinMax('sensFilterValue').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensFilterValue" class="mr-2">{{ this.getDiagDataMinMax('sensFilterValue').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="sensFilterRawTime" ref="sensFilterRawTime" :min="0" :max="100000"
                                                v-model="this.getDiagDataMinMax('sensFilterRawTime').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataMinMax('sensFilterRawTime').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="sensFilterRawTime" class="mr-2">{{ this.getDiagDataMinMax('sensFilterRawTime').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMinMax" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('minmax')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Schirmregler">
                        <Panel header="Schaltschwellen">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMin" ref="schaltschwelleMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMin" class="mr-2">{{ getDiagDataCfg('schaltschwelleMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMax" ref="schaltschwelleMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMax" class="mr-2">{{ getDiagDataCfg('schaltschwelleMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Motor-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="laufzeit1" ref="laufzeit1" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('laufzeit1').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="laufzeit1" class="mr-2">Schirm Laufzeit</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="reglerart" ref="reglerart" :min="0" :max="100"
                                            v-model="getDiagDataCfg('reglerart').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('reglerart').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="reglerart" class="mr-2">{{ getDiagDataCfg('reglerart').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="radiationFilterTime" ref="radiationFilterTime" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('radiationFilterTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('radiationFilterTime').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="radiationFilterTime" class="mr-2">{{ getDiagDataCfg('radiationFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="modeDelay" ref="modeDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('modeDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="modeDelay" class="mr-2">Modus-Verzögerung (Umschaltung)</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div v-show="true" class="hidden md:inline-flex social-icon">
                        <i :class="moduleData.metadata.icon + ' fs-xxlarge icon-md'"></i>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <widget-mini-binary-pulse label="Ventil" :value="this.getModuleValue('OUT_Co2Valve')"></widget-mini-binary-pulse>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <div class="flex flex-column mr-2">

                        </div>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_Co2')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :title="'Bedarf/Betrieb'" :value1="this.getModuleValue('OUT_Request')" :value2="this.getModuleValue('OUT_Co2Valve')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'akt. Dauer/Sperrzeit'" :value1="this.getModuleValue('OUT_Runtime')" :value2="this.getModuleValue('OUT_CurrentForceoffTime')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Periode Sollwert/akt. Sollwert'" :value1="this.getModuleValue('OUT_AktPeriode.co2Setpoint')" :value2="this.getModuleValue('OUT_Co2Setpoint')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Differenz/Lft.Soll'" :value1="this.getModuleValue('OUT_Diff')" :value2="this.getModuleValue('OUT_SetpointVents')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Starts heute/gestern'" :value1="this.getModuleValue('OUT_StartsToday')" :value2="this.getModuleValue('OUT_StartsYesterday')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Dauer heute/gestern'" :value1="this.getModuleValue('OUT_RuntimeTotal')" :value2="this.getModuleValue('OUT_RuntimeYesterday')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-column w-full">
                            <div class="flex-1 align-items-center justify-content-center mr-3">
                                <ul class="widget-list">
                                    <Widget_rowValues2 :title="'🕒'" :value1="this.getModuleValue('OUT_LastStartDt')" :value2="this.getModuleValue('OUT_LastEndDt')"></Widget_rowValues2>
                                </ul>
                            </div>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Perioden" subtitle="Einstellungen Perioden" icon="fi fi-rr-time-twenty-four" fgColor="yellow" bgColor="darkSteel"
                                :badge="periodIndex + 1" @click="toggleOP($event, 'periods')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Einstellungen" subtitle="Einstellungen Regler" icon="fi fi-rr-settings-sliders" fgColor="yellow" bgColor="darkSteel"
                                @click="toggleOP($event, 'minmax')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex mt-3 justify-content-center">
                        <widget-mini-icon :value="this.getModuleValue('OUT_ReleaseVents')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_ReleaseRt')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_ReleaseRh')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_ReleaseRhDef')"></widget-mini-icon>
                        <widget-mini-icon :value="this.getModuleValue('OUT_ReleaseWind')"></widget-mini-icon>
                    </div>
                    <valuebox-enat title="aktuelle Periode" :value="getModuleValue('OUT_AktPeriode.periodName').value" :moduleData="moduleData" icon="fi fi-rr-time-twenty-four" class="mt-2" />
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_Fan').icon" :node="getModuleValue('OUT_Fan')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniBinaryPulse from '@/components/fragments/widgetMiniBinaryPulse.vue';
import widgetMiniIcon from '@/components/fragments/widgetMiniIcon.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetCo2Controller',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniBinaryPulse,
        widgetMiniIcon,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriode.periodIndex').value
        },
        periodCheck: function () {
            if (this.nodeDataPeriods.length > 0) {
                let cnt = 0;
                this.nodeDataPeriods.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'periodEnable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
        operateState: function () {
            if (this.getModuleValue('OUT_Fan').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataMinMax: [],
            nodeDataMinMaxOld: [],
            nodeDataEnergy: [],
            nodeDataEnergyOld: [],
            nodeDataShading: [],
            nodeDataShadingOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            diagPeriod: 0,
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagEnergy: false,
            showDiagShading: false,
            showDiagMisc: false,
            showDiagMinMax: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataEnergy(displayName) {
            if (this.nodeDataEnergy.length > 0 && displayName) {
                const result = this.nodeDataEnergy.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataShading(displayName) {
            if (this.nodeDataShading.length > 0 && displayName) {
                const result = this.nodeDataShading.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataMinMax(displayName) {
            if (this.nodeDataMinMax.length > 0 && displayName) {
                const result = this.nodeDataMinMax.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
            this.diagPeriod = 0;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'periods') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.periods*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPeriods[child.index])) {
                                            this.nodeDataPeriods[child.index] = [];
                                        }
                                        this.nodeDataPeriods[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPeriodsOld = JSON.parse(JSON.stringify(this.nodeDataPeriods));
                            console.log(this.nodeDataPeriods);
                            console.log(this.nodeDataPeriodsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPeriods = true;
                });        
            } else if (grp === 'minmax') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataMinMax = JSON.parse(JSON.stringify(response));
                            this.nodeDataMinMaxOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataMinMax);
                            console.log(this.nodeDataMinMaxOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMinMax = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagTogglePeriods() {
            if (this.showDiagPeriods) {
                this.showDiagPeriods = false;
            } else {
                this.showDiagPeriods = true;
            }
            this.hideOP();
        },
        diagToggleEnergy() {
            if (this.showDiagEnergy) {
                this.showDiagEnergy = false;
            } else {
                this.showDiagEnergy = true;
            }
            this.hideOP();
        },
        diagToggleShading() {
            if (this.showDiagShading) {
                this.showDiagShading = false;
            } else {
                this.showDiagShading = true;
            }
            this.hideOP();
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periods' && !this.periodCheck) {
                    console.log(this.nodeDataPeriods);
                    console.log(this.nodeDataPeriodsOld);
                    const flatHzg = this.nodeDataPeriods.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPeriodsOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'minmax') {
                    console.log(this.nodeDataMinMax);
                    console.log(this.nodeDataMinMaxOld);
                    const flatHzg = this.nodeDataMinMax.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataMinMaxOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periods') this.showDiagPeriods = false;
                            if (diag === 'minmax') this.showDiagMinMax = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periods' && !this.periodCheckHzg) {
                        this.saveDiagNoChanges();
                        this.showDiagPeriods = false;
                    } else if (diag === 'periods' && this.periodCheckHzg) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    } else if (diag === 'minmax') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child1) {
                const child = JSON.parse(node.child1);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        focusDiag(event) {
            this.periodDiagDelay(event);
        },
        periodDiagDelay(event) {
            setTimeout(() => {
                this.diagPeriod = this.periodIndex;
                this.focusElement(event);
            }, 300);
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
        calcLux(val, mode) {
            return helpers.calcLight(val, mode);
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>